.img--bg--about{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: top center;
}

.socialdiv{
  display: flex;
  justify-content: space-evenly;
}

.button--squared.belgique {
  background: #ffcb00 !important;
}

@media (max-width:575.98px){
  .aside-dropdown .button{
    padding: 10px;
  }
  .aside-dropdown__item:nth-of-type(2) {
    margin-top: 10px;
  }
}
